import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { authState } from "../redux/authSlice";
import logo from "../assets/blogcraft_black_t.png";
import LoginSmall from "./LoginSmall";

export default function Home() {
  window.scrollTo(0, 0);
  const navigate = useNavigate();
  const state = useSelector(authState);
  return (
    <div className="flex flex-col flex-1 p-5 space-y-5 bg-white">
      <img className="object-contain w-full h-48" src={logo} alt="logo" />

      <div className="max-w-lg mx-auto text-center">
        <h1 className="mb-4 text-4xl font-bold">BlogCraft.ai</h1>

        <h2 className="mb-6 text-2xl font-semibold">
          Power Your Blogging Journey
        </h2>

        <div className="px-4">
          <h3 className="mb-3 text-xl font-medium">
            Streamline Your Blog Creation Process
          </h3>
          <p className="mb-4">
            BlogCraft.ai acts as your personal blogging coach. Our AI-driven
            platform helps you to formulate a compelling topic, lay out the
            structure, and generate in-depth blog posts related to that theme.
          </p>

          <h3 className="mb-3 text-xl font-medium">
            Revolutionize Your Blogging Approach
          </h3>
          <p>
            Experience a systematic, step-by-step approach to blog creation that
            simplifies your planning process and helps you craft engaging and
            insightful content for your audience.
          </p>
        </div>
      </div>
      <div className="pb-7">
        {!state.uid ? (
          <LoginSmall />
        ) : (
          <button
            className="mx-auto btn btn-primary"
            onClick={() => {
              window.scrollTo(0, 0);
              navigate("/projects");
            }}
          >
            Create A Blog
          </button>
        )}
      </div>
    </div>
  );
}
