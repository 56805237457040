import { useSelector } from "react-redux";
import { authState } from "../redux/authSlice";
import { useParams } from "react-router-dom";
import { BlogType, defaultBlog } from "../typings/BlogType";
import { FieldDefinitionType } from "../typings/FieldDefinitionType";
import DocumentForm from "../components/DocumentForm";
import { useEffect, useState } from "react";
import useCollection from "../hooks/useCollection";
import useDocument from "../hooks/useDocument";
import { useProject } from "../hooks/useProject";

export default function Blog() {
  const { projectId, blogId } = useParams<{
    projectId?: string;
    blogId?: string;
  }>();
  const [project, prompt] = useProject(projectId);
  const state = useSelector(authState);
  const [blog, , , , ,] = useDocument<BlogType>(
    `users/${state.uid}/projects/${projectId}/blogs`,
    blogId || ""
  );
  const [loadedItems, loading, add, update, remove, error, blogsSummary] =
    useCollection<BlogType>(`users/${state.uid}/projects/${projectId}/blogs`);

  const blogs: BlogType[] = loadedItems
    .filter((item) => item.id)
    .sort((a, b) => (a?.order || 0) - (b?.order || 0));

  const [fieldDefinitions, setFieldDefinitions] = useState<
    FieldDefinitionType[] | null
  >(null);

  useEffect(() => {
    if (
      prompt &&
      project &&
      blogsSummary &&
      blog &&
      blog.name &&
      project.name &&
      blog.description
    ) {
      setFieldDefinitions([
        {
          field: "name",
          name: "Name",
          type: "text",
          minRows: 1,
          systemPrompt: `Here is a blog post currently titled "${blog.name}" for the "${project.name}" blog:\n\n${blog.content}`,
          userPrompt: `Suggest a title for this blog post in the following writing style: ${project.writingStyle}. The title should relate directly to the blog content, and should be 5 words or less. Provide the title only, without quotations marks or punctuation.`,
        },
        {
          field: "summary",
          name: "Summary",
          type: "textarea",
          minRows: 2,
          systemPrompt: prompt + `\n\Blogs:\n` + blogsSummary,
          userPrompt: `Write a 60-word summary of the "${blog.name}" blog post of the "${project.name}" blog.`,
          relatedFields: ["summary", "description"],
        },
        {
          field: "description",
          name: "Detailed Description",
          type: "textarea",
          minRows: 4,
          systemPrompt: prompt + `\nBlog posts:\n` + blogsSummary,
          userPrompt: `Write a 500-word detailed overview of the "${blog.name}" blog post of the "${project.name}" blog, focusing solely on the content without any titles or headings.`,
          relatedFields: ["summary", "description"],
        },
        {
          field: "content",
          name: "Full Content",
          type: "textarea",
          minRows: 6,
          systemPrompt: `Here is the content for the "${blog.name}" blog post of the "${project.name}" blog:\n\n${blog.description}`,
          userPrompt: `Write a blog post based on the content, in the following writing style: ${project.writingStyle}. Ensure that the blog post is well-written, includes detailed examples, and focuses solely on the content without any titles or headings.`,
          relatedFields: ["promptHelp"],
        },
        {
          field: "promptHelp",
          name: "Prompt Help",
          type: "textarea",
          minRows: 3,
        },

        { field: "notes", name: "Notes", type: "textarea", minRows: 3 },
      ]);
    }
  }, [blog, blogsSummary, project, prompt]);

  return (
    <div className="flex flex-col p-5 space-y-3 text-black bg-white">
      {project && (
        <div className="text-2xl font-semibold">Project: {project.name}</div>
      )}
      {state.uid && fieldDefinitions && projectId && blogId && (
        <DocumentForm<BlogType>
          initialValues={defaultBlog}
          fieldDefinitions={fieldDefinitions}
          title={`Selected Blog`}
          collectionName={`users/${state.uid}/projects/${projectId}/blogs/`}
          docId={blogId}
        />
      )}
    </div>
  );
}
