import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { authState } from "../redux/authSlice";
import { useProject } from "../hooks/useProject";
import CollectionList from "../components/CollectionList";
import { ApiMessageType } from "../typings/ApiMessageType";
import { processChat, processChat4 } from "../utils/processChat";
import { BlogType } from "../typings/BlogType";
import useCollection from "../hooks/useCollection";
import { ModuleType } from "../typings/ModuleType";
import { FieldDefinitionType } from "../typings/FieldDefinitionType";
import { LessonType } from "../typings/LessonType";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase.config";

export default function Blogs() {
  const state = useSelector(authState);
  const { projectId } = useParams<{ projectId?: string }>();
  const [project, prompt] = useProject(projectId);

  const [modules, , , , , , modulesSummary] = useCollection<ModuleType>(
    `users/${state.uid}/projects/${projectId}/modules`
  );

  const [loadedItems, loading, add, update, remove, error, blogsSummary] =
    useCollection<BlogType>(`users/${state.uid}/projects/${projectId}/blogs`);

  const blogs: BlogType[] = loadedItems
    .filter((item) => item.id)
    .sort((a, b) => (a?.order || 0) - (b?.order || 0));

  if (!state.uid) return <div>No user</div>;
  if (!projectId) return <div>No story</div>;
  if (!project) return <div>Loading</div>;
  if (!modulesSummary) return <div>Loading</div>;
  if (!modules) return <div>Loading</div>;

  const generateItemsForModule = async (module: ModuleType) => {
    if (!module || !module.name) return null;

    const systemPrompt = `You will generate content for the following project titled: "${project.name}"\n\nSynopsis:\n${project.synopsis}\n\nHere is the full module list for "${project.name}":\n${modulesSummary}\n\n`;

    const userPrompt = `Generate blogs for the ${module.name} module in JSON format as follows: [{"name":"blog name", "summary":"blog summary"}, ...]\n\nEach summary should be 100 words or less.`;

    try {
      let messages: ApiMessageType[] = [
        {
          role: "system",
          content: systemPrompt,
        },
        {
          role: "user",
          content: userPrompt,
        },
      ];

      console.log("MESSAGES", messages);
      const response = await processChat({ messages });
      console.log("RESPONSE", response);
      if (!response) throw new Error("No response");
      let dataObject = JSON.parse(response);
      return dataObject.map((item: BlogType) => ({ ...item }));
    } catch (error) {
      console.error("Error generating items: ", error);
      return null;
    }
  };

  const generateMultipleItems = async () => {
    let blogList: BlogType[] = [];
    let blogNumber = 0;

    for (let module of modules) {
      const path = `users/${state.uid}/projects/${projectId}/modules/${module.id}/lessons`;
      const collectionRef = collection(db, path);

      let items: LessonType[] = [];
      try {
        const snapshot = await getDocs(collectionRef);
        const loadedItems = snapshot.docs.map(
          (doc) => ({ id: doc.id, ...doc.data() } as LessonType)
        );
        items = loadedItems
          .filter((item) => item.id)
          .sort((a, b) => (a?.order || 0) - (b?.order || 0));
      } catch (error) {
        console.log("Error getting documents: ", error);
      }

      if (items) {
        for (let item of items) {
          console.log("ITEM", item);
          blogNumber++;
          blogList.push({
            ...item,
            moduleId: module.id,
            moduleName: module.name,
            order: blogNumber,
          });
        }
      }
    }

    console.log("BLOGLIST", blogList);
    return blogList;
  };

  function getFieldDefinitions(blog: BlogType) {
    if (
      prompt &&
      project &&
      blogsSummary &&
      blog &&
      blog.name &&
      blog.description
    ) {
      const fieldDefinitions: FieldDefinitionType[] = [
        {
          field: "content",
          name: "Full Content",
          type: "textarea",
          minRows: 6,
          systemPrompt: `Here is the content for the "${blog.name}" blog post of the "${project.name}" blog:\n\n${blog.description}`,
          userPrompt: `Write a blog post based on the content, in the following writing style: ${project.writingStyle}. Ensure that the blog post is well-written, includes detailed examples, and focuses solely on the content without any titles or headings.`,
          relatedFields: ["promptHelp"],
        },
      ];

      return fieldDefinitions;
    } else return null;
  }

  function getTitleFieldDefinitions(blog: BlogType) {
    if (prompt && project && blogsSummary && blog && blog.name) {
      const fieldDefinitions: FieldDefinitionType[] = [
        {
          field: "name",
          name: "Name",
          type: "text",
          minRows: 1,
          systemPrompt: `Here is a blog post currently titled "${blog.name}" for the "${project.name}" blog:\n\n${blog.content}`,
          userPrompt: `Suggest a title for this blog post in the following writing style: ${project.writingStyle}. The title should relate directly to the blog content, and should be 5 words or less. Provide the title only, without quotations marks or punctuation.`,
        },
      ];

      return fieldDefinitions;
    } else return null;
  }

  const generateAutoIdea = async (
    systemPromptInput: string,
    userPromptInput: string,
    relatedFields: string[],
    promptHelp: string,
    blog: BlogType
  ) => {
    if (!systemPromptInput || !userPromptInput) return;

    const systemPrompt = systemPromptInput;

    const related = relatedFields
      ?.map((relatedField) => {
        if (blog[relatedField as keyof BlogType]?.toString()) {
          return `${relatedField}: ${blog[
            relatedField as keyof BlogType
          ]?.toString()}\n\n`;
        } else {
          return "";
        }
      })
      .join("");

    let userPrompt = userPromptInput + "\n\n";
    if (related.length > 0) userPrompt += "Additional information: " + related;
    if (promptHelp)
      userPrompt +=
        "Follow these priority instructions in your response: " + promptHelp;
    console.log("SYSTEM PROMPT", systemPrompt);
    console.log("USER PROMPT", userPrompt);

    try {
      let messages: ApiMessageType[] = [
        {
          role: "system",
          content: systemPrompt,
        },
        {
          role: "user",
          content: userPrompt,
        },
      ];

      console.log("MESSAGES", messages);

      const response = await processChat4({ messages });
      console.log("RESPONSE", response);

      if (!response) throw new Error("No response");

      return response;
    } catch (error) {
      console.error("Error generating items: ", error);
      return null;
    }
  };

  async function generateBlogs() {
    for (const blog of blogs) {
      if (!blog.id) return;
      const fieldDefinitions = getFieldDefinitions(blog);
      if (fieldDefinitions) {
        for (const fieldData of fieldDefinitions) {
          const { systemPrompt, userPrompt, relatedFields } = fieldData;
          if (systemPrompt && userPrompt) {
            const response = await generateAutoIdea(
              systemPrompt,
              userPrompt,
              relatedFields || [],
              "",
              blog
            );
            if (response) {
              const updatedBlog = { ...blog, [fieldData.field]: response };
              update(blog.id, updatedBlog);
            }
          }
        }
      }
    }
  }

  async function generateTitles() {
    for (const blog of blogs) {
      if (!blog.id) return;
      const fieldDefinitions = getTitleFieldDefinitions(blog);
      if (fieldDefinitions) {
        for (const fieldData of fieldDefinitions) {
          const { systemPrompt, userPrompt, relatedFields } = fieldData;
          if (systemPrompt && userPrompt) {
            const response = await generateAutoIdea(
              systemPrompt,
              userPrompt,
              relatedFields || [],
              "",
              blog
            );
            if (response) {
              const updatedBlog = { ...blog, [fieldData.field]: response };
              update(blog.id, updatedBlog);
            }
          }
        }
      }
    }
  }

  return (
    <CollectionList
      collectionName={`users/${state.uid}/projects/${projectId}/blogs`}
      title="Blogs"
      baseUrl={`/projects/${projectId}/blogs/`}
      generateMultipleItems={generateMultipleItems}
      generateAutopilot={generateBlogs}
      generateTitles={generateTitles}
    />
  );
}
