import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../assets/blogcraft_white_t.png";
import { authState } from "../redux/authSlice";
import { MenuIcon } from "../icons/MenuIcon";

export default function Header() {
  const state = useSelector(authState);
  const [showMenu, setShowMenu] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  console.log("STATE", state);

  const menuItemsLoggedIn =
    state.selectedProject && state.selectedModule
      ? [
          {
            label: "Blogs",
            link: "/projects/" + state.selectedProject + "/blogs",
          },
          {
            label: "Lessons",
            link:
              "/projects/" +
              state.selectedProject +
              "/modules/" +
              state.selectedModule +
              "/lessons",
          },

          {
            label: "Modules",
            link: "/projects/" + state.selectedProject + "/modules",
          },
          { label: "Project", link: "/projects/" + state.selectedProject },
          { label: "Library", link: "/projects" },
          { label: "Account", link: "/account" },
        ]
      : state.selectedProject
      ? [
          {
            label: "Blogs",
            link: "/projects/" + state.selectedProject + "/blogs",
          },
          {
            label: "Modules",
            link: "/projects/" + state.selectedProject + "/modules",
          },
          { label: "Project", link: "/projects/" + state.selectedProject },

          { label: "Library", link: "/projects" },
          { label: "Account", link: "/account" },
        ]
      : [
          { label: "Library", link: "/projects" },
          { label: "Account", link: "/account" },
        ];
  const menuItemsLoggedOut = [
    { label: "Pricing", link: "/pricing" },
    { label: "Login", link: "/login" },
  ];

  console.log("MENU ITEMS", menuItemsLoggedIn);

  useEffect(() => {
    if (showMenu) {
      document.body.classList.add("noScroll");
    } else {
      document.body.classList.remove("noScroll");
    }

    return () => {
      document.body.classList.remove("noScroll");
    };
  }, [showMenu]);

  const menuItems = state.uid ? menuItemsLoggedIn : menuItemsLoggedOut;

  function isActive(path: string) {
    console.log("PATH=======", path);
    const matchLength = getMatchLength(path);
    return matchLength > 0 && matchLength === maxMatchLength;
  }

  function getMatchLength(path: string) {
    return location.pathname.startsWith(path) ? path.length : 0;
  }
  const matchLengths = menuItems.map((item) => getMatchLength(item.link));

  const maxMatchLength = Math.max(...matchLengths);

  const menuDisplay = menuItems.map((item, index) => (
    <div
      key={index}
      onClick={() => {
        setShowMenu(false);
        navigate(item.link);
      }}
      className={isActive(item.link) ? "header-active" : "header-inactive"}
    >
      {item.label}
    </div>
  ));

  const sideBar = (
    <div className={`sidebarContainer ${showMenu ? "show" : ""} h-screen`}>
      <div className="menuItemsWrapper">
        {menuItems.map((item, index) => (
          <div
            key={index}
            onClick={() => {
              setShowMenu(false);
              navigate(item.link);
            }}
            className={
              isActive(item.link) ? "header-active" : "header-inactive"
            }
          >
            {item.label}
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <div className="h-full text-white bg-[#4f45e3] shadow-md">
      <div className="z-50 flex items-center justify-between max-w-5xl px-5 mx-auto text-sm h-14">
        <div className="flex items-center space-x-2 h-14">
          <span
            onClick={() => setShowMenu(!showMenu)}
            className="flex items-center justify-center p-2 font-bold text-white cursor-pointer md:hidden hover:bg-[#1494ff] hover:rounded-full material-symbols-rounded transition-all duration-300"
          >
            {MenuIcon}
          </span>

          <img
            onClick={() => {
              setShowMenu(false);
              navigate("/");
            }}
            className="h-full py-2 cursor-pointer cover hover:opacity-50"
            src={logo}
            alt="logo"
          />
          <div className="text-3xl font-semibold">BlogCraft.ai</div>
        </div>

        <div className="hidden md:flex">{menuDisplay}</div>
      </div>
      <div
        className={`relative flex w-full h-full md:hidden ${
          showMenu ? "show" : ""
        }`}
      >
        <div
          className={`overlay fixed left-0 z-40 flex w-full h-screen bg-black bg-opacity-50 top-14 ${
            showMenu ? "show" : ""
          }`}
        />
        {sideBar}
      </div>
    </div>
  );
}
