import { useSelector } from "react-redux";
import { authState } from "../redux/authSlice";
import { useParams } from "react-router-dom";
import { LessonType, defaultLesson } from "../typings/LessonType";
import { FieldDefinitionType } from "../typings/FieldDefinitionType";
import { useProject } from "../hooks/useProject";
import DocumentForm from "../components/DocumentForm";
import { useEffect, useState } from "react";
import useCollection from "../hooks/useCollection";
import useDocument from "../hooks/useDocument";

export default function Lesson() {
  const { projectId, moduleId, lessonId } = useParams<{
    projectId?: string;
    moduleId?: string;
    lessonId?: string;
  }>();
  const [project, prompt] = useProject(projectId, moduleId);
  const state = useSelector(authState);

  const [, , , , , , summary] = useCollection<LessonType>(
    `users/${state.uid}/projects/${projectId}/modules/${moduleId}/lessons`
  );
  const [lesson, , , , ,] = useDocument<LessonType>(
    `users/${state.uid}/projects/${projectId}/modules/${moduleId}/lessons`,
    lessonId || ""
  );

  console.log(summary);
  const [fieldDefinitions, setFieldDefinitions] = useState<
    FieldDefinitionType[] | null
  >(null);

  useEffect(() => {
    if (prompt && project && summary && lesson && lesson.name) {
      setFieldDefinitions([
        {
          field: "name",
          name: "Name",
          type: "text",
          minRows: 1,
          systemPrompt: `Here is a blog post currently titled "${lesson.name}" for the "${project.name}" blog:\n\n${lesson.lecture}`,
          userPrompt: `Suggest a title for this blog post in the following writing style: ${project.writingStyle}. The title should relate directly to the blog content, and should be 5 words or less. Provide the title only, without quotations marks or punctuation.`,
        },
        {
          field: "summary",
          name: "Summary",
          type: "textarea",
          minRows: 2,
          systemPrompt:
            prompt +
            `\n\nLessons list for the ${state.selectedModuleName} module:\n` +
            summary,
          userPrompt: `Write a 60-word summary of the "${lesson.name}" lesson of the ${state.selectedModuleName} module.`,
          relatedFields: ["summary", "description"],
        },
        {
          field: "description",
          name: "Detailed Description",
          type: "textarea",
          minRows: 4,
          systemPrompt:
            prompt +
            `\n\nChapter list for the ${state.selectedModuleName} module of the ${project.name} book:\n` +
            summary,
          userPrompt: `Please provide a 500-word detailed summary of the "${lesson.name}" chapter of the ${state.selectedModuleName} module of the ${project.name} book, focusing solely on the content without any titles or headings.`,
          relatedFields: ["summary", "description"],
        },

        {
          field: "lecture",
          name: "Full Lecture",
          type: "textarea",
          minRows: 6,
          systemPrompt: `Here is the content for the "${lesson.name}" blog post of the "${project.name}" blog:\n\n${lesson.description}`,
          userPrompt: `Write a blog post based on the content, in the following writing style: ${project.writingStyle}. Ensure that the blog post is well-written, includes detailed examples, and focuses solely on the content without any titles or headings.`,
          relatedFields: ["promptHelp"],
        },
        {
          field: "promptHelp",
          name: "Prompt Help",
          type: "textarea",
          minRows: 3,
        },

        { field: "notes", name: "Notes", type: "textarea", minRows: 3 },
      ]);
    }
  }, [lesson, prompt, project, summary, state.selectedModuleName]);

  return (
    <div className="flex flex-col p-5 space-y-3 text-black bg-white">
      {project && (
        <div className="text-2xl font-semibold">
          Project: {project.name} / {state.selectedModuleName}
        </div>
      )}
      {state.uid &&
        fieldDefinitions &&
        lessonId &&
        projectId &&
        project &&
        lesson && (
          <DocumentForm<LessonType>
            initialValues={defaultLesson}
            fieldDefinitions={fieldDefinitions}
            title={`Lesson`}
            collectionName={`users/${state.uid}/projects/${projectId}/modules/${moduleId}/lessons/`}
            docId={lessonId}
            autopilot={["description", "lecture"]}
          />
        )}
    </div>
  );
}
