import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";

const initialState: AuthType = {
  uid: null,
  email: null,
  displayName: null,
  authIsReady: false,
  admin: false,
  site: false,
  all: 0,
  recent: 0,
  premium: false,
  start: 0,
  end: 0,
  selectedProject: null,
  selectedModule: null,
  selectedModuleName: null,
};

export type AuthType = {
  uid: string | null;
  email: string | null;
  displayName: string | null;
  admin: boolean;
  authIsReady: boolean;
  site: boolean;
  recent: number;
  all: number;
  premium: boolean;
  start: number;
  end: number;
  selectedProject: string | null;
  selectedModule: string | null;
  selectedModuleName: string | null;
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login(state, action) {
      state.uid = action.payload.uid;
      state.email = action.payload.email;
      state.displayName = action.payload.displayName;
    },
    admin(state, action) {
      state.admin = action.payload;
    },
    premium(state, action) {
      state.premium = action.payload;
    },
    selectedProject(state, action) {
      state.selectedProject = action.payload;
    },
    selectedModule(state, action) {
      state.selectedModule = action.payload.id;
      state.selectedModuleName = action.payload.name;
    },
    subscription(state, action) {
      state.start = action.payload.start;
      state.end = action.payload.end;
    },
    logout(state) {
      state.uid = null;
      state.email = null;
      state.displayName = null;
      state.admin = false;
      state.premium = false;
      state.all = 0;
      state.recent = 0;
      state.start = 0;
      state.end = 0;
    },
    authReady(state, action) {
      state.uid = action.payload.uid;
      state.email = action.payload.email;
      state.displayName = action.payload.displayName;
      state.authIsReady = true;
    },
    site(state, action) {
      state.site = action.payload;
    },
    count(state, action) {
      state.all = action.payload.all;
      state.recent = action.payload.recent;
    },
  },
});

export const {
  login,
  admin,
  premium,
  selectedProject,
  selectedModule,
  subscription,
  logout,
  authReady,
  site,
  count,
} = authSlice.actions;

export const authState = (state: RootState) => state.auth;
export default authSlice.reducer;
