import { onAuthStateChanged } from "firebase/auth";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { auth, functions } from "./firebase.config";
import { useDisable } from "./hooks/useDisable";
import ClipLoader from "react-spinners/ClipLoader";
import {
  admin,
  authReady,
  authState,
  logout,
  premium,
  site,
} from "./redux/authSlice";
import isUserPremium from "./stripe/isUserPremium";
import { checkAdmin } from "./utils/checkAdmin";
import { httpsCallable } from "firebase/functions";
import Main from "./Main";

export default function App() {
  useDisable();
  const [sitePassword, setSitePassword] = useState("");
  const dispatch = useDispatch();
  const state = useSelector(authState);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const isAuthorized = await httpsCallable(
      functions,
      "checkPassword"
    )(sitePassword);

    dispatch(site(isAuthorized.data));
    if (isAuthorized) {
      window.localStorage.setItem("BlogCraftPasswordPassed", "True");
    }
  };

  useEffect(() => {
    if (window.localStorage.getItem("BlogCraftPasswordPassed")) {
      dispatch(site(true));
    }
  }, [dispatch]);

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      dispatch(
        authReady({
          uid: user?.uid,
          email: user?.email,
          displayName: user?.displayName,
        })
      );
      if (user) {
        checkAdmin({ uid: user.uid }).then((response) => {
          dispatch(admin(response));
        });
        const isPremium = await isUserPremium();
        dispatch(premium(isPremium));
      } else {
        dispatch(logout());
        window.localStorage.removeItem("BlogCraftPasswordPassed");
      }
    });
  }, [dispatch]);

  if (!state.authIsReady)
    return (
      <div className="flex items-center justify-center w-screen h-screen">
        <ClipLoader color="#000" size={100} />
      </div>
    );

  if (state.site) return <Main />;

  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <form onSubmit={handleSubmit}>
        <input
          className="px-3 py-2 border rounded-md outline-none"
          type="password"
          placeholder="Enter Password"
          onChange={(e) => setSitePassword(e.target.value)}
        />
      </form>
    </div>
  );
}
