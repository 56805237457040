import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";
import { getFunctions } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyCjiLsZqDefrdG9jWHdWlfBDP3N5WhDrzs",
  authDomain: "blogcraft.ai",
  projectId: "blogcraftai",
  storageBucket: "blogcraftai.appspot.com",
  messagingSenderId: "605687732894",
  appId: "1:605687732894:web:fe658da9ce635646b0f98f",
  measurementId: "G-PP2KW12DW4",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app);
const functions = getFunctions(app);

export { db, storage, auth, functions, analytics };
