export type LessonType = {
  //Editable Properties
  name?: string;
  summary?: string;
  description?: string;
  lecture?: string;
  promptHelp?: string;
  notes?: string;

  //Read-Only Properties
  order?: number;
  wordCount?: number;
  createdSeconds?: number;
  updatedSeconds?: number;
  locked?: boolean;

  //ID Properties
  id?: string;
  projectId?: string;
  moduleId?: string;
  moduleName?: string;
};

export const defaultLesson: LessonType = {
  name: "",
  summary: "",
  description: "",
  lecture: "",
  promptHelp: "",
  notes: "",
};
