import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { authState } from "../redux/authSlice";
import { useProject } from "../hooks/useProject";
import CollectionList from "../components/CollectionList";
import useCollection from "../hooks/useCollection";
import { LessonType } from "../typings/LessonType";
import { FieldDefinitionType } from "../typings/FieldDefinitionType";
import { ApiMessageType } from "../typings/ApiMessageType";
import { processChat } from "../utils/processChat";

export default function Lessons() {
  const state = useSelector(authState);
  const { projectId, moduleId } = useParams<{
    projectId?: string;
    moduleId?: string;
  }>();
  const [project, prompt] = useProject(projectId, moduleId);
  const [loadedItems, loading, add, update, remove, error, summary] =
    useCollection<LessonType>(
      `users/${state.uid}/projects/${projectId}/modules/${moduleId}/lessons`
    );

  const lessons: LessonType[] = loadedItems
    .filter((item) => item.id)
    .sort((a, b) => (a?.order || 0) - (b?.order || 0));

  function getFieldDefinitions(lesson: LessonType) {
    if (prompt && project && summary && lesson && lesson.name) {
      const fieldDefinitions: FieldDefinitionType[] = [
        {
          field: "description",
          name: "Detailed Description",
          type: "textarea",
          minRows: 4,
          systemPrompt:
            prompt +
            `\n\nChapter list for the ${state.selectedModuleName} module of the ${project.name} book:\n` +
            summary,
          userPrompt: `Please provide a 500-word detailed summary of the "${lesson.name}" chapter of the ${state.selectedModuleName} module of the ${project.name} book, focusing solely on the content without any titles or headings.`,
          relatedFields: ["summary", "description"],
        },
      ];

      return fieldDefinitions;
    } else return null;
  }

  const generateAutoIdea = async (
    systemPromptInput: string,
    userPromptInput: string,
    relatedFields: string[],
    promptHelp: string,
    lesson: LessonType
  ) => {
    if (!systemPromptInput || !userPromptInput) return;

    const systemPrompt = systemPromptInput;

    const related = relatedFields
      ?.map((relatedField) => {
        if (lesson[relatedField as keyof LessonType]?.toString()) {
          return `${relatedField}: ${lesson[
            relatedField as keyof LessonType
          ]?.toString()}\n\n`;
        } else {
          return "";
        }
      })
      .join("");

    let userPrompt = userPromptInput + "\n\n";
    if (related.length > 0) userPrompt += "Additional information: " + related;
    if (promptHelp)
      userPrompt +=
        "Follow these priority instructions in your response: " + promptHelp;
    console.log("SYSTEM PROMPT", systemPrompt);
    console.log("USER PROMPT", userPrompt);

    try {
      let messages: ApiMessageType[] = [
        {
          role: "system",
          content: systemPrompt,
        },
        {
          role: "user",
          content: userPrompt,
        },
      ];

      console.log("MESSAGES", messages);

      const response = await processChat({ messages });
      console.log("RESPONSE", response);

      if (!response) throw new Error("No response");

      return response;
    } catch (error) {
      console.error("Error generating items: ", error);
      return null;
    }
  };

  async function generateLessons() {
    for (const lesson of lessons) {
      if (!lesson.id) return;
      const fieldDefinitions = getFieldDefinitions(lesson);
      if (fieldDefinitions) {
        for (const fieldData of fieldDefinitions) {
          const { systemPrompt, userPrompt, relatedFields } = fieldData;
          if (systemPrompt && userPrompt) {
            const response = await generateAutoIdea(
              systemPrompt,
              userPrompt,
              relatedFields || [],
              "",
              lesson
            );
            if (response) {
              const updatedLesson = { ...lesson, [fieldData.field]: response };
              update(lesson.id, updatedLesson);
            }
          }
        }
      }
    }
  }

  if (!state.uid) return <div>No user</div>;
  if (!projectId) return <div>No project</div>;
  if (!project) return <div>Loading</div>;

  return (
    <CollectionList
      collectionName={`users/${state.uid}/projects/${projectId}/modules/${moduleId}/lessons`}
      title={`Lessons for ${state.selectedModuleName}`}
      baseUrl={`/projects/${projectId}/modules/${state.selectedModule}/lessons/`}
      systemPrompt={`Generate a sequence of chapter summaries for the following book:\n Titled ${project.name}\nSynopsis:\n${project.synopsis}. \n\nThe chapters should be for the following part of the book:\n${state.selectedModuleName}`}
      userPrompt={`Now, generate a sequence of chapter summaries. Each chapter summary sould be about 60 words. The chapters should breakdown the module into a sequence of smaller ideas. Return the list in JSON format as follows: [{"name":"chapter name", "summary":"chapter summary", "order": 1}, {"name":"chapter name", "summary":"chapter summary", "order": 2}, ...].`}
      generateAutopilot={generateLessons}
    />
  );
}
