import { signOut } from "firebase/auth";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { logout, authState } from "../redux/authSlice";
import { auth } from "../firebase.config";

import { createCheckoutSession } from "../stripe/createCheckoutSessions";
import { getPortalLink } from "../stripe/getPortalLink";
import useSubscription from "../stripe/useSubscription";
import { toast } from "react-toastify";
import RowDisplay from "../components/RowDisplay";

export default function Account() {
  window.scrollTo({ top: 0, behavior: "smooth" });

  const state = useSelector(authState);
  const dispatch = useDispatch();

  const [plan, setPlan] = useState("");
  const { start, end } = useSubscription();

  useEffect(() => {
    setPlan(state.premium ? "Premium" : "Not Subscribed");
  }, [state.premium]);

  return (
    <div className="flex-1 bg-white">
      <div className="flex flex-col max-w-3xl p-5 mx-auto space-y-5">
        <h1 className="px-3 py-2 text-3xl font-bold text-center border rounded-md">
          Account
        </h1>
        <div className="flex flex-col space-y-3">
          <RowDisplay label="Name" value={state.displayName || ""} />
          <RowDisplay label="Email" value={state.email || ""} />
          <RowDisplay label="User Id" value={state.uid || ""} />
          <RowDisplay label="Summaries All Time" value={state.all.toString()} />
          <RowDisplay
            label="Summaries Previous 30 Days (Max 200)"
            value={state.recent.toString()}
          />
          <RowDisplay label="Plan" value={plan} />
          <RowDisplay
            label="Current Period Start"
            value={new Date(start * 1000).toLocaleString()}
          />
          <RowDisplay
            label="Current Period End"
            value={new Date(end * 1000).toLocaleString()}
          />
        </div>

        <div className="flex mx-auto space-x-3">
          {state.premium ? (
            <button
              className="btn btn-secondary"
              onClick={() => {
                toast.loading("Connecting Stripe...");
                getPortalLink();
              }}
            >
              View Subscription
            </button>
          ) : (
            <button
              className="btn btn-primary"
              onClick={() => {
                toast.loading("Connecting Stripe...");
                if (state.uid) createCheckoutSession(state.uid);
              }}
            >
              Upgrade
            </button>
          )}
          <button
            className="btn btn-secondary"
            onClick={() => {
              signOut(auth);
              dispatch(logout());
            }}
          >
            Sign out
          </button>
        </div>
      </div>
    </div>
  );
}
